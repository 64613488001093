/** @jsxImportSource @emotion/react */
import React, { useState, useRef } from "react";
import axios from "axios";
import { css } from "@emotion/react";
import {
  getPublicStudies,
  getPublicStudy,
} from "../lichess_chess_dot_com/get_studies";
import {
  getPublicLichessGames,
  getChesssComGames,
} from "../lichess_chess_dot_com/get_games";
import { Instructions } from "./instructions";
import { insert } from "formik";
import { makePgn } from "chessops/pgn";
axios.defaults.withCredentials = true;

export const ReviewOpening = () => {
  const initialFile: any = "";
  const intialResults: any = "";
  const initialSelectedStudy: any = "";
  const initialOpeningStudyByID: any = "";
  const initialGameFileByStudyID: any = "";
  const initialStudies: any[] = [];
  const initialExcludeMoveIndicators = {
    blunder: { value: false, symbol: "??" },
    mistake: { value: false, symbol: "?" },
    speculative: { value: false, symbol: "!?" },
    dubious: { value: false, symbol: "?!" },
  };
  const [openingFile, setOpeningFile] = useState(initialFile);
  const [lichessOpeningUsername, setLichessOpeningUsername] = useState("");
  const [gameUsername, setGameUsername] = useState("");
  const [gameFile, setGameFile] = useState(initialFile);
  const [gamePGN, setGamePGN] = useState("");
  const [results, setResults] = useState(intialResults);
  const [processing, setProcessing] = useState(false);
  const [processingDots, setProcessingDots] = useState("");
  const [openingStudyID, setOpeningStudyID] = useState("");
  const [gameFileStudyID, setGameFileStudyID] = useState("");
  const [gameFileByStudyID, setGameFileByStudyID] = useState(
    initialGameFileByStudyID
  );
  const gameFileInputRef = useRef<HTMLInputElement>(null);
  const [openingStudyByID, setOpeningStudyByID] = useState(
    initialOpeningStudyByID
  );
  const [lichessStudies, setlichessStudies] = useState(initialStudies);
  const [selectedLichessStudy, setSelectedLichessStudy] =
    useState(initialSelectedStudy);
  const initialGames: any[] = [];
  const [retrievedGames, setRetrievedGames] = useState(initialGames);
  const initialSelectedGame: any = "";
  const [selectedGameIndex, setSelectedGameIndex] =
    useState(initialSelectedGame);
  const [selectedColor, setSelectedColor] = useState("white");
  const [excludeMoveIndicators, setExcludeMoveIndicators] = useState(
    initialExcludeMoveIndicators
  );
  const [showInstructions, setShowInstructions] = useState(false);
  const [gettingGames, setGettingGames] = useState(false);
  const instructionsRef = useRef<HTMLDialogElement>(null);
  const openingFileInputRef = useRef<HTMLInputElement>(null);

  const openInstructions = () => {
    setShowInstructions(true);
    if (instructionsRef.current) {
      instructionsRef.current.showModal();
    }
  };

  const closeInstructions = () => {
    setShowInstructions(false);
    if (instructionsRef.current) {
      instructionsRef.current.close();
    }
  };
  function handleOpeningFileChange(event: any) {
    if (event.target.value.length > 0) {
      setOpeningFile(event.target.files[0]);
      setSelectedLichessStudy("");
      console.log("Handling opening file by file change");
      setOpeningStudyID("");
    } else {
      setOpeningFile("");
    }
  }
  function handleGameFileChange(event: any) {
    if (event.target.value.length > 0) {
      setGameFile(event.target.files[0]);
      setGamePGN("");
      setGameFileByStudyID("");
      setGameFileStudyID("");
    } else {
      setGameFile("");
    }
  }

  function processWithDots(duration: number) {
    let dots = "";
    let intervalId = setInterval(() => {
      dots += ".";
      if (dots.length > 9) {
        dots = "";
      }
      setProcessingDots("Processing" + dots);
    }, 500);

    setTimeout(() => {
      clearInterval(intervalId);
      console.log("Processing complete!");
    }, duration);
  }

  async function handleDoConsistencyCheck(color: string) {
    try {
      console.log(openingFile);
      console.log("env", process.env.NODE_ENV);
      if (
        openingStudyID !== "" ||
        openingFile !== "" ||
        selectedLichessStudy !== ""
      ) {
        let openingText = "";

        let nameOfOpeningFile = "";

        if (openingFile !== "") {
          openingText = await openingFile.text();
          nameOfOpeningFile = openingFile.name;
        }
        if (selectedLichessStudy !== "") {
          openingText = await getPublicStudy(selectedLichessStudy.id);
          nameOfOpeningFile = selectedLichessStudy.name;
        }
        if (openingStudyID !== "") {
          const result = await getPublicStudy(openingStudyID);
          console.log("Study by id", result);
          openingText = result;
          nameOfOpeningFile = result.split("\n")[0];
          setOpeningStudyByID(result);
          setSelectedLichessStudy("");
          setOpeningFile("");
          if (openingFileInputRef.current) {
            openingFileInputRef.current.value = "";
          }
        }

        processWithDots(10000);
        setProcessing(true);

        const url =
          process.env.NODE_ENV === "production"
            ? "https://revchess.com/api/consistency_check"
            : "http://localhost:5001/api/consistency_check";
        const formData = new FormData();
        formData.append("opening_text", openingText);
        formData.append("color", selectedColor);
        for (const [k, v] of Object.entries(excludeMoveIndicators)) {
          if (v.value) {
            formData.append("excludeSymbols[]", v.symbol);
          }
        }
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        console.log("posting");
        const response = await axios.post(url, formData, config);
        console.log("back");

        setProcessing(false);
        console.log("Resp", response.data);
        let results = response.data;
        if (results === "") {
          results = "No inconsistencies found";
        }
        results =
          "Consistency Results for: " + nameOfOpeningFile + "\n" + results;

        setResults(results);
      } else {
        setResults("Please choose an opening file above");
      }
    } catch (e) {
      setProcessing(false);
      setResults("Sorry...something went wrong, we'll look into it:" + e);
      console.log("Errozzzzzzr", e);
    }
  }
  async function handleSubmit() {
    try {
      console.log(openingFile);
      console.log("env", process.env.NODE_ENV);
      if (
        (openingFile !== "" ||
          selectedLichessStudy !== "" ||
          openingStudyID !== "") &&
        (gameFile !== "" ||
          gamePGN !== "" ||
          gameFileStudyID !== "" ||
          selectedGameIndex !== "")
      ) {
        processWithDots(10000);
        setProcessing(true);
        let gameText = "";
        let nameOfGameFile = "";
        if (gameFile !== "") {
          gameText = await gameFile.text();
          nameOfGameFile = gameFile.name;
        }
        if (gamePGN !== "") {
          gameText = gamePGN;
          nameOfGameFile = "Used Pasted PGN";
        }
        if (selectedGameIndex !== "") {
          const game = retrievedGames[selectedGameIndex];
          gameText = makePgn(game);
          nameOfGameFile =
            game.headers.get("White") +
            " vs. " +
            game.headers.get("Black") +
            " " +
            game.headers.get("Date")?.slice(5) +
            " " +
            game.headers.get("UTCTime")?.slice(0, -3);
        }
        if (gameFileStudyID !== "") {
          const gameFile = await getPublicStudy(gameFileStudyID);
          console.log("Game File by Study by id", gameFile);
          setGameFileByStudyID(gameFile);
          setGameFile("");
          setGamePGN("");
          gameText = gameFileByStudyID;
          nameOfGameFile = "Lichess Study with ID:" + gameFileStudyID;
        }

        let openingText = "";

        let nameOfOpeningFile = "";

        if (openingFile !== "") {
          openingText = await openingFile.text();
          nameOfOpeningFile = openingFile.name;
        }
        if (selectedLichessStudy !== "") {
          openingText = await getPublicStudy(selectedLichessStudy.id);
          nameOfOpeningFile = selectedLichessStudy.name;
        }
        if (openingStudyID !== "") {
          const studyByID = await getPublicStudy(openingStudyID);
          console.log("Study by id", studyByID);
          setOpeningStudyByID(studyByID);
          setSelectedLichessStudy("");
          setOpeningFile("");
          if (openingFileInputRef.current) {
            openingFileInputRef.current.value = "";
          }
          openingText = openingStudyByID;
          nameOfOpeningFile = "Lichess study with ID:" + openingStudyID;
        }

        const url =
          process.env.NODE_ENV === "production"
            ? "https://revchess.com/api/check_opening"
            : "http://localhost:5001/api/check_opening";
        const formData = new FormData();
        formData.append("opening_text", openingText);
        formData.append("game_text", gameText);
        formData.append("opening_text", openingText);
        formData.append("color", selectedColor);
        for (const [k, v] of Object.entries(excludeMoveIndicators)) {
          if (v.value) {
            formData.append("excludeSymbols[]", v.symbol);
          }
        }
        formData.forEach((next) => console.log(next));
        const config = {
          withCredentials: true,
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        console.log("posting");
        const response = await axios.post(url, formData, config);
        console.log("back");

        setProcessing(false);
        console.log("Resp", response.data);
        let results = response.data;
        results =
          "Opening: " +
          nameOfOpeningFile +
          "\n" +
          "Game(s):" +
          nameOfGameFile +
          "\n" +
          results;
        setResults(results);
      } else {
        let message = "";

        if (
          openingFile === "" &&
          selectedLichessStudy === "" &&
          openingStudyID === ""
        ) {
          message = "Please select an opening file\n";
        }
        if (gameFile === "" && gamePGN === "" && gameFileStudyID === "") {
          message = message + "Please select a game file";
        }
        setResults(message);
      }
    } catch (e) {
      setProcessing(false);
      setResults("Sorry...something went wrong, we'll look into it:" + e);
      console.log("Errozzzzzzr", e);
    }
  }

  const excludeHTML: any[] = [];
  const myKeys = Object.keys(excludeMoveIndicators);
  myKeys.forEach((next) => {
    let nextIndicator: keyof typeof excludeMoveIndicators =
      next as keyof typeof excludeMoveIndicators;
    excludeHTML.push(
      <label key={nextIndicator}>
        {excludeMoveIndicators[nextIndicator]["symbol"]}
        <input
          type="checkbox"
          checked={excludeMoveIndicators[nextIndicator]["value"]}
          onChange={() => {
            setExcludeMoveIndicators({
              ...excludeMoveIndicators,
              [nextIndicator]: {
                symbol: excludeMoveIndicators[nextIndicator].symbol,
                value: !excludeMoveIndicators[nextIndicator].value,
              },
            });
          }}
        ></input>
      </label>
    );
  });
  return (
    <div css={containerCss}>
      <button
        onClick={() => {
          openInstructions();
        }}
      >
        Features & Instructions
      </button>
      <dialog ref={instructionsRef}>
        <Instructions closeFunction={closeInstructions}></Instructions>
      </dialog>
      <div>Select Opening:</div>
      <div css={openingContainerCss}>
        <div css={optionCss}>
          <div css={optionHeadingCss}>Upload pgn file:</div>
          <input
            id="choose_opening"
            ref={openingFileInputRef}
            type="file"
            name="opening_file"
            onChange={handleOpeningFileChange}
          />
        </div>
        <div css={optionCss}>
          <div css={optionHeadingCss}>Or Get List of Studies:</div>
          <div>
            <label>Lichess Username:</label>
            <input
              value={lichessOpeningUsername}
              onChange={(e) => setLichessOpeningUsername(e.target.value)}
            ></input>
            <button
              onClick={async () => {
                const studies = await getPublicStudies(lichessOpeningUsername);
                setlichessStudies(studies);
              }}
            >
              Get Studies
            </button>
            <div>Select Opening Study:</div>
            <ul css={studyListCss}>
              <li
                onClick={() => {
                  setSelectedLichessStudy("");
                  if (openingFileInputRef.current) {
                    openingFileInputRef.current.value = "";
                  }
                }}
                style={{
                  backgroundColor:
                    selectedLichessStudy === "" ? "LightGray" : "white",
                }}
              >
                None...using file upload or study id
              </li>
              {lichessStudies.map((item: any) => (
                <li
                  key={item.id}
                  style={{
                    backgroundColor:
                      selectedLichessStudy === item ? "LightGray" : "white",
                  }}
                  onClick={() => {
                    setSelectedLichessStudy(item);
                    setOpeningFile("");
                    setOpeningStudyByID("");
                    if (openingFileInputRef.current) {
                      openingFileInputRef.current.value = "";
                    }
                  }}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div css={optionCss}>
          <div css={optionHeadingCss}>Or Get Lichess Study by ID:</div>
          <div>
            <label>Lichess Study ID:</label>
            <input
              value={openingStudyID}
              onChange={(e) => {
                setOpeningStudyID(e.target.value);
                if (openingFileInputRef.current) {
                  openingFileInputRef.current.value = "";
                }
              }}
            ></input>
            <div>
              <div>{openingStudyByID.split("\n")[0]}</div>
            </div>
          </div>
        </div>
      </div>
      <div>Opening Settings:</div>
      <div css={consistencyCheckCss}>
        <div>
          <div>Opening Color:</div>
          <label>
            <input
              type="radio"
              value="white"
              checked={selectedColor === "white"}
              onChange={(e) => {
                setSelectedColor(e.target.value);
              }}
            ></input>
            white
          </label>
          <label>
            <input
              type="radio"
              value="black"
              checked={selectedColor === "black"}
              onChange={(e) => {
                setSelectedColor(e.target.value);
              }}
            ></input>
            black
          </label>
        </div>
        <div>
          Indicators that exclude move for opening color:(can select none or
          multiple):
        </div>
        <div css={indicatorOptionsCss}>{excludeHTML}</div>
        <button
          onClick={async () => {
            await handleDoConsistencyCheck(selectedColor);
          }}
        >
          Perform Optional Consistency Check
        </button>
      </div>
      <div>Game(s)</div>

      <div css={openingContainerCss}>
        <div css={optionCss}>
          <div css={optionHeadingCss}>Upload file:</div>
          <input
            ref={gameFileInputRef}
            id="choose_game"
            type="file"
            name="game_file"
            onChange={handleGameFileChange}
          />
        </div>
        <div css={optionCss}>
          <div css={optionHeadingCss}>Or Paste pgn:</div>
          <textarea
            value={gamePGN}
            onChange={(e) => setGamePGN(e.target.value)}
            css={gamePGNCss}
          ></textarea>
        </div>

        <div css={optionCss}>
          <div css={optionHeadingCss}>Or Get List of Recent Games:</div>
          <div>
            <label>Username:</label>
            <input
              value={gameUsername}
              onChange={(e) => setGameUsername(e.target.value)}
            ></input>
            <div>
              <button
                onClick={async () => {
                  setGettingGames(true);
                  const games = await getPublicLichessGames(gameUsername);
                  if (games.length === 0) {
                    setResults(
                      "No games found..either no games or no such user"
                    );
                  }
                  setGettingGames(false);
                  setRetrievedGames(games);
                }}
              >
                Get Lichess
              </button>
              <button
                onClick={async () => {
                  setGettingGames(true);
                  const games = await getChesssComGames(gameUsername);
                  if (games.length === 0) {
                    setResults(
                      "No games found..either no games or no such user"
                    );
                  }
                  setRetrievedGames(games);
                  setGettingGames(false);
                }}
              >
                Get Chess.com
              </button>
            </div>
            <div>Select Games:</div>
            {gettingGames ? (
              <div css={studyListCss}>Retrieving Games</div>
            ) : (
              <ul css={studyListCss}>
                <li
                  onClick={() => {
                    setSelectedGameIndex("");
                    if (openingFileInputRef.current) {
                      openingFileInputRef.current.value = "";
                    }
                  }}
                  style={{
                    backgroundColor:
                      selectedGameIndex === "" ? "LightGray" : "white",
                  }}
                >
                  None...using file upload or study id
                </li>
                {retrievedGames.map((item: any, index) => (
                  <li
                    key={index}
                    style={{
                      backgroundColor:
                        selectedGameIndex === index ? "LightGray" : "white",
                    }}
                    onClick={() => {
                      setSelectedGameIndex(index);
                      setGameFile("");
                      setGameFileStudyID("");
                      if (gameFileInputRef.current) {
                        gameFileInputRef.current.value = "";
                      }
                    }}
                  >
                    {item.headers.get("White") +
                      " vs. " +
                      item.headers.get("Black") +
                      " " +
                      item.headers.get("Date")?.slice(5) +
                      " " +
                      item.headers.get("UTCTime")?.slice(0, -3)}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div css={optionCss}>
          <div css={optionHeadingCss}>
            Or Get Lichess Study With Game(s) by ID:
          </div>
          <div>
            <label>Lichess Study ID:</label>
            <input
              value={gameFileStudyID}
              onChange={(e) => {
                setGameFileStudyID(e.target.value);
                if (gameFileInputRef.current != null) {
                  gameFileInputRef.current.value = "";
                }
              }}
            ></input>
            <div>
              <div>{gameFileByStudyID.split("\n")[0]}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button onClick={() => handleSubmit()}>
          Compare game(s) to opening
        </button>
      </div>
      <div>Results:</div>
      <textarea
        css={resultsCss}
        value={processing ? processingDots : results}
        readOnly
      ></textarea>
    </div>
  );
};

const indicatorOptionsCss = css`
  display: flex;
  justify-content: space-around;
`;

const studyListCss = css`
  list-style-type: none;
  font-family: Inter, monospace, "Courier", Courier, monospace, sans-serif;
  font-size: 10px;
  text-align: left;
  border: solid 1px;
  max-height: 75px;
  overflow: auto;
  margin: 5px;
  padding: 0;
`;

const openingContainerCss = css`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: solid;
  max-width: 400px;
  padding: 5px;
  margin-bottom: 25px;
`;

const containerCss = css`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 5px;
`;

const consistencyCheckCss = css`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: solid;
  max-width: 400px;
  padding: 5px;
  margin-bottom: 25px;
`;
const gameCss = css`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: solid;
  max-width: 400px;
  padding: 5px;
  margin-bottom: 25px;
`;
const gamePGNCss = css`
  min-width: 270px;
`;

const resultsCss = css`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: solid;
  min-height: 400px;
  max-width: 600px;
  padding: 5px;
  margin-bottom: 25px;
`;

const optionCss = css`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin-bottom: 10px;
`;

const optionHeadingCss = css`
  text-align: center;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  &:before,
  :after {
    content: " ";
    flex: 1 0 0%;
    height: 1.5px;
    background: darkgray;
    margin: 0 0.5rem 0 0;
  }
  &:after {
    margin: 0 0 0 0.5rem;
  }
`;
// .line2 {
// 	margin:0 0 1em;
// 	color:#F00;
// 	text-shadow:1px 1px 1px #000;
// 	font-size:1.5em;
// 	text-align:center;
//   display:flex;
//   align-items:center;
// }
// .line2:before,.line2:after {
// 	content:" ";
//   flex:1 0 0%;
// 	height:2px;
// 	background:rgba(0,0,0,0.8);
//   margin:0 1.5rem 0 0;
// }
// .line2:after {
//   margin:0 0 0 1.5rem;
// }
