import readNDJSONStream from "ndjson-readablestream";
import { parsePgn } from "chessops/pgn";
import { number } from "yup";
async function toText(body: any) {
  const reader = body.getReader();
  const decoder = new TextDecoder();
  const chunks: any[] = [];

  async function read(): Promise<any> {
    const { done, value } = await reader.read();

    if (done) {
      return chunks.join("");
    }

    const chunk = decoder.decode(value, { stream: true });
    chunks.push(chunk);
    return read();
  }

  return read();
}
export async function getChesssComGames(username: string): Promise<any[]> {
  const currentDate = new Date(Date.now());
  let number_of_months_to_get = 2;
  let games: any = [];
  for (let i = 0; i < number_of_months_to_get; i++) {
    let month = currentDate.getMonth() + 1 - i;
    let year = currentDate.getFullYear();
    //getMonth is zero based so we need to do a little adjusting to get previous
    //month in correct format and go into previous month.
    //We want two months so don't need to adjust otherwise
    if (month === 0) {
      year = year - 1;
      month = 12;
    }
    console.log(year);
    const response = await fetch(
      `https://api.chess.com/pub/player/` +
        username +
        `/games/` +
        year +
        `/` +
        month +
        `/pgn`,
      {
        cache: "no-cache",
      }
    );
    console.log(response, response.status);
    if (response.ok) {
      let gamesPgn = await toText(response.body);
      games = games.concat(parsePgn(gamesPgn));
    }
  }
  console.log("Games", games);
  return games;
}

export async function getPublicLichessGames(username: string): Promise<any[]> {
  const response = await fetch(
    `https://lichess.org/api/games/user/` + username + `?max=80`,
    {
      cache: "no-cache",
    }
  );

  if (response.ok) {
    let gamesPgn = await toText(response.body);
    let games = parsePgn(gamesPgn);
    return games;
  } else {
    return [];
  }
}

export async function getPublicStudy(id: string): Promise<string> {
  try {
    const headers = {
      Authorization: "Bearer lip_C7ogCYOHkYq74NpmO4Fl",
    };
    const response = await fetch(
      `https://lichess.org/api/study/` + id + `.pgn`,
      { cache: "no-cache", headers }
    );
    if (response.body !== null) {
      //const reader = response.body.getReader();
      let pgn = await new Response(response.body).text();
      return pgn;
    } else {
      return "";
    }
  } catch (e) {
    console.log("Get Study Error:", e);
    return e;
  }
}
export async function getStudy(id: string): Promise<void> {
  try {
    const headers = {
      Authorization: "Bearer lip_C7ogCYOHkYq74NpmO4Fl",
    };
    const response = await fetch(
      `https://lichess.org/api/study/` + id + `.pgn`,
      {
        headers,
        cache: "no-cache",
      }
    );
    if (response.body !== null) {
      //const reader = response.body.getReader();
      let pgn = await new Response(response.body).text();
      console.log(pgn);
    }
  } catch (e) {
    console.log("Get Study Error:", e);
    return e;
  }
}
