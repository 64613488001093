/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { alternateBorders } from "../css/css";
import React from "react";

export function Instructions(props: { closeFunction: any }) {
  return (
    <div css={appContainerCss}>
      <button
        onClick={() => {
          props.closeFunction();
        }}
      >
        Close X
      </button>
      <div>Contact:info@revchess.com</div>
      <ul>
        <li>
          Overview of what the app can do:
          <ul>
            <li>
              "Consistency Check": Determine if your opening recommends two
              different moves for the same postion
            </li>
            <li>
              "Compare game to opening":Determine what move in a game went out
              of the opening
            </li>
          </ul>
        </li>
        <li>
          Enter Opening in one of three ways:
          <ul>
            <li>
              Upload pgn:<br></br>You can upload a pgn file
            </li>
            <li>
              Lichess Username:<br></br>If you provide a username and click "Get
              Studies", a list of all public studies for that user will appear
              and you can select one to use.
            </li>
            <li>
              Study id:<br></br> If you enter the study id of a public Lichess
              study,the app will retrieve and use that study.
              <br></br>
              The study id can be found in the url of the study which you can
              get by clicking the share button at Lichess. For example, the
              study id of https://lichess.org/study/Y1AUr3T5 is Y1AUr3T5. If you
              have the url of a specific chapter, the study id is the portion
              after the word study in the url. For example, with
              https://lichess.org/study/Y1AUr3T5/O2bynzTu, Y1AUr3T5 is the study
              id. Don't use the chapter id at the end of the url.
            </li>
          </ul>
        </li>
        <li>Consistency Check</li>
        <ul>
          <li>
            Once you entered an opening, select what color the opening is for
            and the click "Perform Consistency Check". In the results section
            the app will identify all board positions (fens), where the opening
            recommends more than one move. It will show those moves and all
            chapters in the study and all pgn's that lead to that
            recommendation. This is optional and not necessary to run if you
            just want to compare a game to an opening.
          </li>
        </ul>
        <li>Enter game(s) in one of four ways:</li>

        <ul>
          <li>Upload pgn file</li>
          <li>Paste pgn</li>
          <li>
            Note:The file or pgn can have more than one game and the app will
            compare each one
          </li>
          <li>
            Select a game from Lichess or Chess.com by providing a username and
            clicking either Get Lichess or Get Chess.com. App will get last 80
            games at Lichess or last 2 months of games at Chess.com
          </li>
          <li>
            Study id:<br></br> If you enter the study id of a public Lichess
            study,the app will retrieve and use that study as the game(s)
            source.
            <br></br>
            The study id can be found in the url of the study which you can get
            by clicking the share button at Lichess. For example, the study id
            of https://lichess.org/study/Y1AUr3T5 is Y1AUr3T5. If you have the
            url of a specific chapter, the study id is the portion after the
            word study in the url. For example, with
            https://lichess.org/study/Y1AUr3T5/O2bynzTu, Y1AUr3T5 is the study
            id. Don't use the chapter id at the end of the url.
          </li>
        </ul>
        <li>
          Comparing game(s) to Opening:<br></br>Click the "Compare game(s) to
          opening" button and the app will display in the resuls area the
          following:
          <ul>
            <li> What move by either player deviated from the opening</li>
            <li>
              What move by either player brought the game back into the opening
              after leaving.
            </li>
            <li>What move went beyond the moves in the opening.</li>
          </ul>
          The results section will show the move(s) that went out of prep or
          brought you back into prep, the pgn leading up to that move and what
          move(s) the opening did cover and the chapter of the opening that
          recommendation was in.
        </li>
      </ul>
    </div>
  );
}

const appContainerCss = css`
  ${alternateBorders()}
`;
